import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/imodium-lingual"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690 active-trail">
                      <a
                        href="/imodium-produkte/"
                        title
                        className="active-trail"
                      >
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-menu-produkte"
                  className="block block-menu"
                >
                  <h2>Produkte</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first last expanded active-trail">
                        <a
                          href="/imodium-produkte/"
                          title
                          className="active-trail"
                        >
                          Produktübersicht
                        </a>
                        <ul className="menu">
                          <li className="first leaf active-trail">
                            <a
                              href="/imodium-lingual/"
                              title
                              className="active-trail active"
                            >
                              IMODIUM<sup>®</sup> lingual
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/imodium-duo/" title>
                              IMODIUM<sup>®</sup> duo
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            <h1>
                              IMODIUM® lingual
                              <br />
                              20 Schmelztabletten
                            </h1>
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="product_detail">
                      <div className="packaging">
                        <div className="left">
                          <div className="field field-name-field-packaging-img field-type-image field-label-hidden">
                            <div className="field-items">
                              <div className="field-item even">
                                <img
                                  typeof="foaf:Image"
                                  src="/sites/default/files/Produkt_lingual_Detail.jpg"
                                  width="322"
                                  height="255"
                                />
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="right">
                          <div className="field field-name-field-packaging-body field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div className="field-item even">
                                <h2>
                                  Die schnelle* Hilfe bei Durchfall, ideal für
                                  unterwegs
                                </h2>
                                <ul>
                                  <li>Schmelzen sofort&nbsp;auf der Zunge</li>
                                  <li>Einfach ohne Wasser einzunehmen</li>
                                </ul>
                                <p>
                                  *Wirkung kann nach etwa 2 Stunden eintreten
                                </p>
                              </div>
                            </div>
                          </div>
                          <a
                            className="packaging_file"
                            href="http://www.swissmedicinfo.ch/Accept.aspx?ReturnUrl=%2f"
                            target="_blank"
                          >
                            Packungsbeilage
                          </a>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even">
                                      Gebrauch
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                            IMODIUM® lingual Schmelztabletten werden zur
                            Behandlung von akutem Durchfall
                            verwendet. Sie schmelzen ohne Zugabe von Wasser auf
                            der Zunge, so dass sie besonders geeignet sind, wenn
                            es schwer fällt, Tabletten zu schlucken, oder
                            für unterwegs und auf Reisen. Durch ihr
                            angenehmes Minz-Aroma eignen sie sich auch, wenn
                            der Durchfall von Übelkeit begleitet wird.
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="left">
                          <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div
                                className="field-item even"
                                property="content:encoded"
                              >
                                <p>
                                  IMODIUM® lingual gibt es als Packung mit 20
                                  Tabletten.
                                  <br />
                                  &nbsp;
                                </p>
                                <h3>
                                  IMODIUM® lingual
                                  <br />
                                  20 Schmelztabletten
                                </h3>
                                <p>&nbsp;</p>
                                <h3>
                                  <img
                                    alt
                                    src="/sites/default/files/wirkstoff.jpg"
                                    style={{
                                      width: "26px",
                                      height: "26px"
                                    }}
                                  />{" "}
                                  Wirkstoff:
                                </h3>
                                <p>
                                  Loperamidhydrochlorid.
                                  <br />
                                  &nbsp;
                                </p>
                                <h3>
                                  <img
                                    alt
                                    src="/sites/default/files/anwendungsgebiet.jpg"
                                    style={{
                                      width: "26px",
                                      height: "26px"
                                    }}
                                  />{" "}
                                  Anwendungsgebiet:
                                </h3>
                                <p>
                                  IMODIUM® lingual ist ein gut
                                  wirksames Mittel gegen plötzlichen (akuten) Durchfall
                                  verschiedenster Ursache. IMODIUM®{" "}
                                  lingual ist eine sich schnell auflösende
                                  Schmelztablette. Die Tablette wird auf die
                                  Zunge gelegt, worauf sie sofort schmilzt und
                                  dann mit dem Speichel geschluckt wird.
                                  Flüssigkeitszufuhr ist für die
                                  Tabletteneinnahme nicht notwendig. Durch ihr angenehmes 
                                  Minz-Aroma eignen sie sich auch,
                                  wenn der Durchfall von Übelkeit begleitet
                                  wird.
                                </p>
                                <p>
                                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                                </p>
                                <p className="p1">
                                  Für akuten Durchfall gibt es ausserdem IMODIUM
                                  ® lingual akut mit 10 oder 12
                                  Schmelztabletten.
                                </p>
                                <table
                                  border="1"
                                  cellPadding="5"
                                  cellSpacing="1"
                                  style={{
                                    width: "100%"
                                  }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        style={{
                                          width: "50%",
                                          backgroundColor: "rgb(165, 165, 165)",
                                        }}
                                      >
                                        <strong
                                          style={{
                                            color: "white",
                                          }}
                                        >
                                          Akuter Durchfall
                                        </strong>
                                      </th>
                                      <th
                                        scope="col"
                                        style={{
                                          width: "50%",
                                          backgroundColor: "rgb(165, 165, 165)",
                                        }}
                                      >
                                        
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          "background-color":
                                            "rgb(225, 225, 225)",
                                          "vertical-align": "top"
                                        }}
                                      >
                                        <p>
                                          <strong>
                                            Dosierung: Kinder von 6 bis 12
                                          </strong>
                                        </p>
                                        <p>
                                          Wenn vom Arzt oder von der Ärztin
                                          nicht anders verordnet:
                                          <br />
                                          Die Anfangsdosis beträgt 1
                                          Schmelztablette, danach nach jedem
                                          weiteren flüssigen Stuhl 1
                                          Schmelztablette einnehmen. Die
                                          Tageshöchstdosis beträgt 3
                                          Schmelztabletten (pro 20kg), jedoch
                                          nicht mehr als 8 Schmelztabletten pro
                                          Tag. Die maximale Behandlungsdauer
                                          darf 2 Tage nicht überschreiten.
                                        </p>
                                      </td>
                                      <td
                                        style={{
                                          "background-color":
                                            "rgb(225, 225, 225)",
                                          "vertical-align": "top"
                                        }}
                                      >
                                        
                                        <p>
                                          <strong>
                                            Dosierung: Jugendliche &amp;
                                            Erwachsene
                                          </strong>
                                        </p>
                                        <p>
                                          Wenn vom Arzt oder von der Ärztin
                                          nicht anders verordnet:
                                          <br />
                                          Die Anfangsdosis beträgt 2
                                          Schmelztabletten, danach nach jedem
                                          weiteren flüssigen Stuhl 1
                                          Schmelztablette einnehmen. Die
                                          Tageshöchstdosis beträgt 8
                                          Schmelztabletten. Die maximale
                                          Behandlungsdauer darf 2 Tage nicht
                                          überschreiten.
                                        </p>
                                      </td>
                                      <td
                                        style={{
                                          "background-color":
                                            "rgb(240, 240, 240)",
                                          "vertical-align": "top"
                                        }}
                                      >
                                        
                                        <p>
                                          Sobald der Stuhlgang wieder normal
                                          ist, oder wenn während mehr als 12
                                          Stunden kein Stuhl mehr ausgeschieden
                                          wurde, ist die Behandlung mit
                                          IMODIUM®&nbsp;lingual zu beenden.
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p>&nbsp;</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="right"></div>
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
